<template>
	<VitePwaManifest />
	<client-only>
		<div class="bg-white min-h-screen text-black flex flex-col items-center w-full" v-if="$device.isMobile">
			<slot />
		</div>
		<div v-else>
	      <QR />
	    </div>
	</client-only>
</template>